import { CartItemInput } from '@graphcommerce/graphql-mesh'
import {
  AddProductsToCartFormProps,
  useFormAddProductsToCart,
} from '@graphcommerce/magento-product'
import { PluginProps } from '@graphcommerce/next-config'
import { nonNullable } from '@graphcommerce/next-ui'
import { useEffect } from 'react'

export const component = 'AddProductsToCartForm'
export const exported = '@graphcommerce/magento-product'

function ApplyConfigurableOptionsHook(props: Pick<AddProductsToCartFormProps, 'defaultValues'>) {
  const { defaultValues } = props
  const { setValue, getValues } = useFormAddProductsToCart()

  useEffect(() => {
    if (defaultValues && 'cartItems' in defaultValues && defaultValues.cartItems?.[0]) {
      if (
        JSON.stringify(getValues('cartItems.0.selected_options')) ===
        JSON.stringify(defaultValues.cartItems[0].selected_options)
      )
        return
      const selectedOptions = defaultValues.cartItems[0].selected_options?.filter(nonNullable)
      if (selectedOptions) setValue('cartItems.0.selected_options', selectedOptions)
    }
  }, [defaultValues, getValues, setValue])

  return null
}

function ApplyConfigurableOptions(props: PluginProps<AddProductsToCartFormProps>) {
  const { Prev, children, ...rest } = props

  return (
    <Prev {...rest}>
      <ApplyConfigurableOptionsHook {...props} />
      {children}
    </Prev>
  )
}

export const Plugin = ApplyConfigurableOptions
